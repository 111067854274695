import { useMemo } from "react";
import commentOn from "../images/comment-on.svg";
import commentOff from "../images/comment-off.svg";

export default function CommentButton({
  endComment,
  startComment,
  loaded,
  addingComment,
}) {
  const commentButton = useMemo(() => {
    if (addingComment) {
      return commentOn;
    } else {
      return commentOff;
    }
  }, [addingComment]);

  const handleNewComment = () => {
    if (loaded) {
      if (addingComment) {
        endComment();
      } else {
        startComment();
      }
    }
  };

  return (
    <button onClick={handleNewComment} className="buttonNoStyle">
      <img
        onClick={handleNewComment}
        src={commentButton}
        alt="Add comment"
        id="commentButton"
      />
    </button>
  );
}
