//handle splitting the time into hours, minutes, seconds
const formatTime = (time) => {
  const hours = Math.floor(time / 3600)
    .toFixed(0)
    .padStart(2, "0");
  const minutes = Math.floor((time / 60) % 60)
    .toFixed(0)
    .padStart(2, "0");
  const seconds = Math.floor(time % 60)
    .toFixed(0)
    .padStart(2, "0");
  const milliseconds = Math.round(time * 100)
    .toFixed(0)
    .padStart(2, "0")
    .slice(-2);
  if (hours > 0) {
    return (
      <span>
        {hours}:{minutes}:{seconds}.{milliseconds}
      </span>
    );
  } else {
    return (
      <span>
        {minutes}:{seconds}.{milliseconds}
      </span>
    );
  }
};

export default formatTime;
