//import audio files
import orama_audio from "../../audio_files/orama_audio.mp3";
import mango_bay_audio from "../../audio_files/mango_bay_audio.mp3";

//import waveform data
import orama_waveform from "../../audio_data_files/orama.json";
import mango_bay_waveform from "../../audio_data_files/mango_bay.json";

const exportedArray = [
  {
    title: "Orama",
    audioSrc: orama_audio,
    waveform: orama_waveform,
    duration: 21.012,
  },
  {
    title: "Mango Bay",
    audioSrc: mango_bay_audio,
    waveform: mango_bay_waveform,
    duration: 276.4103333333333,
  },
];

export default exportedArray;
