import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import handleLoadAudio from "./waveform/handleLoadAudio";

const AudioComponent = forwardRef(
  (
    {
      song,
      isPlaying,
      setCurrentTime,
      toggleLoaded,
      isMuted,
      volume,
      playbackSpeed,
      loaded,
      startTime,
      endTime,
      duration,
      setStartTime,
      currentTime,
      currentPercentage,
      toggleIsPlaying,
      isCommentPlaying,
    },
    ref
  ) => {
    const [audioElement, setAudioElement] = useState(null);

    // load audio
    useImperativeHandle(ref, () => ({
      initAudio() {
        setAudioElement(handleLoadAudio(song));
        toggleLoaded(true);
      },

      // set current time
      setAudioTime(time) {
        setAudioElement((audioElement) => {
          audioElement.currentTime = time;
          return audioElement;
        });
        setCurrentTime(time);
      },
    }));

    // if isPlaying is true, play the song, else pause it
    useEffect(() => {
      if (loaded) {
        if (isPlaying) {
          setAudioElement((audioElement) => {
            audioElement.play();
            return audioElement;
          });
        } else {
          setAudioElement((audioElement) => {
            audioElement.pause();
            return audioElement;
          });
        }
      }
    }, [isPlaying, loaded]);

    // use the comment playback if isCommentPlaying doesn't equal null
    useEffect(() => {
      if (isCommentPlaying !== null) {
        if (isCommentPlaying) {
          setAudioElement((audioElement) => {
            audioElement.play();
            return audioElement;
          });
        } else {
          setAudioElement((audioElement) => {
            audioElement.pause();
            return audioElement;
          });
        }
      }
    }, [isCommentPlaying, loaded]);

    // when the song is playing, set the current time of the song every 10 ms
    useEffect(() => {
      if ((loaded && isPlaying) || isCommentPlaying) {
        const timerInterval = setInterval(function () {
          if (startTime > 0 && endTime < duration) {
            setStartTime(startTime + audioElement.currentTime - currentTime);
          } else if (endTime < duration && currentPercentage > 0.5) {
            setStartTime(startTime + audioElement.currentTime - currentTime);
          }
          setCurrentTime(audioElement.currentTime);
        }, 10);
        return () => clearInterval(timerInterval);
      }
    }, [
      isPlaying,
      loaded,
      setCurrentTime,
      audioElement?.currentTime,
      startTime,
      endTime,
      duration,
      currentTime,
      setStartTime,
      currentPercentage,
      isCommentPlaying,
    ]);

    // mute or unmute the song depending on the value of isMuted
    useEffect(() => {
      if (loaded) {
        setAudioElement((audioElement) => {
          audioElement.muted = isMuted;
          return audioElement;
        });
      }
    }, [isMuted, loaded]);

    // when the song ends, set the time to 0 and call handleAudioEnded
    useEffect(() => {
      if (loaded) {
        if (audioElement.ended) {
          setAudioElement((audioElement) => {
            audioElement.currentTime = 0;
            return audioElement;
          });
          toggleIsPlaying(false);
          setStartTime(0);
          setCurrentTime(0);
        }
      }
    }, [
      loaded,
      audioElement?.ended,
      setStartTime,
      toggleIsPlaying,
      setCurrentTime,
    ]);

    // change the playback speed of the song is the value of playbackSpeed changes
    useEffect(() => {
      if (loaded) {
        setAudioElement((audioElement) => {
          audioElement.playbackRate = playbackSpeed;
          return audioElement;
        });
      }
    }, [playbackSpeed, loaded]);

    // when volume changes set the volume of the song to volume
    useEffect(() => {
      if (loaded) {
        setAudioElement((audioElement) => {
          audioElement.volume = volume;
          return audioElement;
        });
      }
    }, [volume, loaded]);
  }
);

export default AudioComponent;
