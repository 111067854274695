import { useMemo } from "react";
import playSVG from "../images/play.svg";
import pauseSVG from "../images/pause.svg";

export default function PlayButton({
  isPlaying,
  toggleIsPlaying,
  initAudio,
  loaded,
  addingComment,
  editingComment,
}) {
  const handlePlayPauseClick = () => {
    if (!loaded) {
      initAudio();
      toggleIsPlaying(true);
    }
    if (addingComment || editingComment) {
      return;
    } else {
      toggleIsPlaying(!isPlaying);
    }
  };

  const playButton = useMemo(() => {
    if (isPlaying) {
      return pauseSVG;
    } else {
      return playSVG;
    }
  }, [isPlaying]);

  return (
    <button onClick={handlePlayPauseClick} className="buttonNoStyle">
      <img src={playButton} alt="play/pause" id="playButton" />
    </button>
  );
}
