import Canvas from "./canvas";

function CreateMarkersCanvas({
  barsNo,
  markers,
  duration,
  startTime,
  endTime,
  canvasHeight,
  setCommentHitRegions,
  commentHitRegions,
  commentHovered,
  editingComment,
}) {
  const draw = (ctx) => {
    ctx.canvas.width = 3 * barsNo;
    ctx.canvas.height = canvasHeight;

    // Get the DPR and size of the canvas
    var dpr = window.devicePixelRatio;
    var rect = ctx.canvas.getBoundingClientRect();

    // Set the "actual" size of the canvas
    ctx.canvas.width = rect.width * dpr;
    ctx.canvas.height = rect.height * dpr;

    // Scale the context to ensure correct drawing operations
    ctx.scale(dpr, dpr);

    // Set the "drawn" size of the canvas
    ctx.canvas.style.width = `${rect.width}px`;
    ctx.canvas.style.height = `${rect.height}px`;

    let tempCommentHitRegions = [];

    const findCommentOpacity = (id, commentHovered, editingComment) => {
      if (editingComment.id) {
        if (editingComment.id !== id) {
          return 0.06;
        } else {
          return 0.6;
        }
      } else if (commentHovered) {
        if (commentHovered !== id) {
          return 0.06;
        } else {
          return 0.3;
        }
      } else {
        return 0.3;
      }
    };

    const findMarkerOpacity = (id, commentHovered, editingComment) => {
      if (editingComment.id) {
        if (editingComment.id !== id) {
          return 0.2;
        } else {
          return 1;
        }
      } else if (commentHovered) {
        if (commentHovered !== id) {
          return 0.2;
        } else {
          return 1;
        }
      } else {
        return 1;
      }
    };

    for (let i = 0; i < markers.length; i++) {
      const row = markers[i];

      //opacity regions between markers
      row.forEach((marker) => {
        ctx.globalAlpha = findCommentOpacity(
          marker.CurrentTime,
          commentHovered,
          editingComment
        );
        let commentRegion = {
          id: marker.CurrentTime,
          x: 0,
          y: 99.5 + 12.5 * i + 2.5,
          width: 0,
          height: 10,
          colour: marker.Colour.hex,
        };
        const colour = marker.Colour.hex;
        ctx.strokeStyle = colour;
        ctx.fillStyle = colour;
        if (marker.EndTime !== null) {
          const position1 =
            ((marker.StartTime - startTime) / (endTime - startTime)) *
            (3 * barsNo);
          const position2 =
            ((marker.EndTime - startTime) / (endTime - startTime)) *
            (3 * barsNo);
          if (marker.StartTime >= startTime && marker.EndTime <= endTime) {
            ctx.fillRect(
              position1,
              99.5 + 12.5 * i + 2.5,
              position2 - position1,
              10
            );
            commentRegion.x = position1 - 2.5;
            commentRegion.width = position2 - position1 + 2.5;
          } else if (
            marker.StartTime >= startTime &&
            marker.StartTime <= endTime &&
            marker.EndTime > endTime
          ) {
            ctx.fillRect(
              position1,
              99.5 + 12.5 * i + 2.5,
              endTime * (3 * barsNo) - position1,
              10
            );
            commentRegion.x = position1 - 2.5;
            commentRegion.width = endTime * (3 * barsNo) - position1;
          } else if (
            marker.StartTime < startTime &&
            marker.EndTime <= endTime &&
            marker.EndTime >= startTime
          ) {
            ctx.fillRect(0, 99.5 + 12.5 * i + 2.5, position2, 10);
            commentRegion.x = 0;
            commentRegion.width = position2 + 2.5;
          } else if (marker.StartTime < startTime && marker.EndTime > endTime) {
            ctx.fillRect(0, 99.5 + 12.5 * i + 2.5, endTime * (3 * barsNo), 10);
            commentRegion.x = 0;
            commentRegion.width = endTime * (3 * barsNo);
          }
        }
        if (commentRegion.width > 0) {
          tempCommentHitRegions.push(commentRegion);
        }
      });
      ctx.globalAlpha = 1;

      //circles at each marker
      row.forEach((marker) => {
        ctx.globalAlpha = findMarkerOpacity(
          marker.CurrentTime,
          commentHovered,
          editingComment
        );
        const colour = marker.Colour.hex;
        ctx.strokeStyle = colour;
        ctx.fillStyle = colour;
        if (marker.EndTime === null) {
          if (marker.StartTime >= startTime && marker.StartTime <= endTime) {
            const position =
              ((marker.StartTime - startTime) / (endTime - startTime)) *
              (3 * barsNo);
            ctx.beginPath();
            ctx.arc(position, 108 + 12.5 * i + 2.5, 5.5, 0, 2 * Math.PI);
            ctx.lineWidth = 1;
            ctx.stroke();
            ctx.beginPath();
            ctx.arc(position, 108 + 12.5 * i + 2.5, 2.5, 0, 2 * Math.PI);
            ctx.fill();
          }
        } else {
          const position1 =
            ((marker.StartTime - startTime) / (endTime - startTime)) *
            (3 * barsNo);
          const position2 =
            ((marker.EndTime - startTime) / (endTime - startTime)) *
            (3 * barsNo);
          if (marker.StartTime >= startTime && marker.StartTime <= endTime) {
            ctx.beginPath();
            ctx.arc(position1, 104.5 + 12.5 * i + 2.5, 5.5, 0, 2 * Math.PI);
            ctx.lineWidth = 1;
            ctx.stroke();
            ctx.beginPath();
            ctx.arc(position1, 104.5 + 12.5 * i + 2.5, 2.5, 0, 2 * Math.PI);
            ctx.fill();
            ctx.globalAlpha = findCommentOpacity(
              marker.CurrentTime,
              commentHovered,
              editingComment
            );
            ctx.beginPath();
            ctx.arc(
              position1,
              104.5 + 12.5 * i + 2.5,
              5,
              0.5 * Math.PI,
              1.5 * Math.PI
            );
            ctx.fill();
            ctx.globalAlpha = 1;
          }
          if (marker.EndTime >= startTime && marker.StartTime <= endTime) {
            ctx.globalAlpha = findCommentOpacity(
              marker.CurrentTime,
              commentHovered,
              editingComment
            );
            ctx.beginPath();
            ctx.arc(
              position2,
              104.5 + 12.5 * i + 2.5,
              5,
              1.5 * Math.PI,
              0.5 * Math.PI
            );
            ctx.fill();
            ctx.globalAlpha = 1;
          }
        }
      });
    }
    if (
      JSON.stringify(tempCommentHitRegions) !==
      JSON.stringify(commentHitRegions)
    ) {
      setCommentHitRegions(tempCommentHitRegions);
    }
  };

  return <Canvas draw={draw} />;
}

export default CreateMarkersCanvas;
