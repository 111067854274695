import React, { useState, useMemo, useRef, useEffect } from "react";
import CommentSection from "./commentSection";
import AudioComponent from "./audioComponent";
import CanvasElements from "./canvasElements";
import MediaControls from "./mediaControls";
import ZoomControls from "./zoomControls";
import CommentMediaControls from "./commentMediaControls";
import "../styles.css";

const AudioPlayer = ({ tracks }) => {
  // Constants for sake of demo
  const trackIndex = 0;
  const barsNo = 214;
  const { audioSrc, waveform, duration } = tracks[trackIndex];
  const waveformLength = waveform.data.length;

  // Media controls
  const [isPlaying, toggleIsPlaying] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [zoom, setZoom] = useState(1);
  const maxZoom = Math.log2(waveformLength / barsNo) + 1;
  const [isMuted, toggleMuted] = useState(false);
  const [volume, setVolume] = useState(0.5);

  // Audio file
  const [currentTime, setCurrentTime] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const endTime = useMemo(() => {
    return startTime + Math.pow(2, 1 - zoom) * duration;
  }, [startTime, zoom, duration]);
  const [loaded, toggleLoaded] = useState(false);
  const childRef = useRef(null);
  const [mousePositionX, setMousePositionX] = useState(null);
  const currentPercentage = (currentTime - startTime) / (endTime - startTime);

  // Commenting
  const [commentList, updateCommentList] = useState([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);
  const [commentStartTime, setCommentStartTime] = useState(null);
  const [commentEndTime, setCommentEndTime] = useState(null);
  const [addingComment, toggleAddingComment] = useState(false);
  const [isCommentPlaying, toggleIsCommentPlaying] = useState(null);
  const [editingComment, setEditingComment] = useState(false);

  // Close commenting features
  const endComment = () => {
    toggleAddingComment(false);
    setCommentStartTime(null);
    setCommentEndTime(null);
  };

  // Find the optimum free row for a new comment. Look through each row until a row is found with no comment which would overlap the comment in question
  const findCommentRow = (commentList, newStartTime, newEndTime) => {
    for (let i = 0; i < commentList.length; i++) {
      if (commentList[i].length === 0) {
        return i;
      } else {
        for (let j = 0; j < commentList[i].length; j++) {
          const commentStartTime = commentList[i][j].StartTime;
          const commentEndTime = commentList[i][j].EndTime;
          if (
            (newStartTime >= commentStartTime &&
              newStartTime <= commentEndTime) ||
            (newEndTime <= commentEndTime && newEndTime >= commentStartTime) ||
            (newStartTime <= commentStartTime && newEndTime >= commentEndTime)
          ) {
            if (commentList[i + 1] === undefined) {
              // There is no room; all 10 rows are being used
              return false;
            }
            break;
          } else if (j === commentList[i].length - 1) {
            return i;
          }
        }
      }
    }
  };

  // handle playback when placing a comment
  useEffect(() => {
    if (!addingComment && !editingComment) {
      toggleIsCommentPlaying(null);
      if (isCommentPlaying) {
        toggleIsPlaying(true);
      }
    }
  }, [addingComment, isCommentPlaying, editingComment]);

  return (
    <>
      <div id="background"></div>
      <div id="content">
        <CanvasElements
          setMousePositionX={setMousePositionX}
          barsNo={barsNo}
          startTime={startTime}
          endTime={endTime}
          waveform={waveform}
          currentPercentage={currentPercentage}
          mousePositionX={mousePositionX}
          zoom={zoom}
          maxZoom={maxZoom}
          currentTime={currentTime}
          commentList={commentList}
          duration={duration}
          commentStartTime={commentStartTime}
          commentEndTime={commentEndTime}
          waveformLength={waveformLength}
          loaded={loaded}
          addingComment={addingComment}
          setAudioTime={(time) => childRef.current.setAudioTime(time)}
          setCommentEndTime={setCommentEndTime}
          setCommentStartTime={setCommentStartTime}
          editingComment={editingComment}
          setEditingComment={setEditingComment}
        />
        <MediaControls
          isPlaying={isPlaying}
          toggleIsPlaying={toggleIsPlaying}
          loaded={loaded}
          addingComment={addingComment}
          currentTime={currentTime}
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          duration={duration}
          toggleMuted={toggleMuted}
          isMuted={isMuted}
          volume={volume}
          playbackSpeed={playbackSpeed}
          setPlaybackSpeed={setPlaybackSpeed}
          setAudioTime={(time) => childRef.current.setAudioTime(time)}
          initAudio={() => childRef.current.initAudio()}
          setVolume={setVolume}
          endComment={endComment}
          toggleAddingComment={toggleAddingComment}
          barsNo={barsNo}
          setCommentStartTime={setCommentStartTime}
          setMousePositionX={setMousePositionX}
          editingComment={editingComment}
        />
        <CommentSection
          commentList={commentList}
          updateCommentList={updateCommentList}
          commentStartTime={commentStartTime}
          commentEndTime={commentEndTime}
          cancelComment={endComment}
          findCommentRow={findCommentRow}
          toggleIsCommentPlaying={toggleIsCommentPlaying}
        />
        <ZoomControls
          zoom={zoom}
          setZoom={setZoom}
          maxZoom={maxZoom}
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          duration={duration}
          currentTime={currentTime}
          currentPercentage={currentPercentage}
          loaded={loaded}
          setAudioTime={(time) => childRef.current.setAudioTime(time)}
        />
        <AudioComponent
          song={audioSrc}
          isPlaying={isPlaying}
          setCurrentTime={setCurrentTime}
          toggleLoaded={toggleLoaded}
          isMuted={isMuted}
          volume={volume}
          playbackSpeed={playbackSpeed}
          loaded={loaded}
          startTime={startTime}
          endTime={endTime}
          duration={duration}
          setStartTime={setStartTime}
          currentTime={currentTime}
          currentPercentage={currentPercentage}
          toggleIsPlaying={toggleIsPlaying}
          isCommentPlaying={isCommentPlaying}
          ref={childRef}
        />
        <CommentMediaControls
          addingComment={addingComment}
          commentStartTime={commentStartTime}
          commentEndTime={commentEndTime}
          startTime={startTime}
          endTime={endTime}
          barsNo={barsNo}
          isCommentPlaying={isCommentPlaying}
          toggleIsCommentPlaying={toggleIsCommentPlaying}
          setAudioTime={(time) => childRef.current.setAudioTime(time)}
          currentTime={currentTime}
          editingComment={editingComment}
          commentList={commentList}
        />
      </div>
    </>
  );
};

export default AudioPlayer;
