import React, { useEffect, useState } from "react";
import volumeButton from "../images/volume.svg";
import muteButton from "../images/mute.svg";

export default function VolumeButton({
  muteAudio,
  changeVolume,
  volume,
  isMuted,
}) {
  const [showVolumeSlider, toggleVolumeSlider] = useState(false);
  const [volumeButtonSVG, setVolumeButtonSVG] = useState(volumeButton);
  const [volumeID, setVolumeID] = useState("volumeButton");

  // if the mouse is hovering over the volume button, show the volume slider
  const handleHideVolumeSlider = () => {
    if (showVolumeSlider) {
      toggleVolumeSlider(false);
    }
  };

  // when the volume is changed, unmute the song if it is muted, and update the volume
  const handleVolumeChange = (e) => {
    if (isMuted) {
      muteAudio(false);
    }
    changeVolume(e.target.value);
  };

  // set the volume button svg depending on the value of isMuted
  useEffect(() => {
    if (isMuted) {
      setVolumeButtonSVG(muteButton);
      setVolumeID("muteButton");
    } else {
      setVolumeButtonSVG(volumeButton);
      setVolumeID("volumeButton");
    }
  }, [isMuted, setVolumeButtonSVG, setVolumeID]);

  return (
    <div id="volume-container" onMouseLeave={handleHideVolumeSlider}>
      <button
        className="volume-button buttonNoStyle"
        onClick={() => muteAudio(!isMuted)}
        onMouseEnter={() => toggleVolumeSlider(true)}
      >
        <img src={volumeButtonSVG} alt="volume button" id={volumeID} />
      </button>
      {showVolumeSlider && (
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={handleVolumeChange}
          id="volumeSlider"
        />
      )}
    </div>
  );
}
