export default function ZoomSlider({ maxZoom, zoom, setNewZoom, loaded }) {
  const handleZoomChange = (e) => {
    if (loaded) {
      const newZoom = parseFloat(e.target.value);
      setNewZoom(Math.pow(2, 1 - newZoom), newZoom);
    }
  };

  return (
    <>
      <input
        type="range"
        min="1"
        max={maxZoom}
        step="0.001"
        value={zoom}
        onChange={(e) => handleZoomChange(e)}
        id="zoomSlider"
      />
    </>
  );
}
