const colours = [
  { hex: "#5D74FE", used: false },
  { hex: "#00D599", used: false },
  { hex: "#33EEFF", used: false },
  { hex: "#EE4B5D", used: false },
  { hex: "#29f1c3", used: false },
  { hex: "#8c14fc", used: false },
  { hex: "#f62459", used: false },
  { hex: "#4d13d1", used: false },
  { hex: "#93faa5", used: false },
  { hex: "#d8fa08", used: false },
  { hex: "#f22613", used: false },
  { hex: "#f9690e", used: false },
  { hex: "#00b5cc", used: false },
];

export default colours;
