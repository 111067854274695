import React from "react";
import ReactDOM from "react-dom/client";
import AudioPlayer from "./components/audioPlayer";

import tracks from "./components/waveform/tracks";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AudioPlayer tracks={tracks} />
  </React.StrictMode>
);
