import VolumeButton from "./volumeButton";
import PlaybackSpeedControls from "./playbackSpeedControls";
import checkIfLoaded from "./checkIfLoaded";
import SkipButtons from "./skipButtons";
import CommentButton from "./commentButton";
import PlayButton from "./playButton";

export default function MediaControls({
  isPlaying,
  toggleIsPlaying,
  loaded,
  addingComment,
  currentTime,
  startTime,
  setStartTime,
  endTime,
  duration,
  toggleMuted,
  isMuted,
  volume,
  playbackSpeed,
  setPlaybackSpeed,
  setAudioTime,
  initAudio,
  setVolume,
  endComment,
  toggleAddingComment,
  barsNo,
  setCommentStartTime,
  setMousePositionX,
  editingComment,
}) {
  const canvasLength = 3 * barsNo;
  const startComment = () => {
    toggleAddingComment(true);
    // setCommentStartTime(currentTime);
    toggleIsPlaying(false);
    // setMousePositionX((currentTime / duration) * canvasLength);
    setMousePositionX(
      ((currentTime - startTime) / (endTime - startTime)) * canvasLength
    );
    // if ((currentTime - startTime) / (endTime - startTime) < 0.8) {
    //   setMousePositionX(
    //     ((currentTime - startTime) / (endTime - startTime) + 0.2) * canvasLength
    //   );
    // } else {
    //   setMousePositionX(
    //     (currentTime / duration) * canvasLength +
    //       (canvasLength - (currentTime / duration) * canvasLength)
    //   );
    // }
  };

  return (
    <>
      <PlayButton
        isPlaying={isPlaying}
        toggleIsPlaying={toggleIsPlaying}
        initAudio={initAudio}
        loaded={loaded}
        addingComment={addingComment}
        editingComment={editingComment}
      />
      <SkipButtons
        loaded={loaded}
        currentTime={currentTime}
        startTime={startTime}
        setStartTime={setStartTime}
        endTime={endTime}
        duration={duration}
        setAudioTime={setAudioTime}
      />
      <VolumeButton
        muteAudio={(state) => checkIfLoaded(toggleMuted, loaded, state)}
        changeVolume={(volume) => checkIfLoaded(setVolume, loaded, volume)}
        volume={volume}
        isMuted={isMuted}
      />
      <PlaybackSpeedControls
        playbackSpeed={playbackSpeed}
        setPlaybackSpeed={setPlaybackSpeed}
        loaded={loaded}
      />
      <CommentButton
        endComment={endComment}
        startComment={startComment}
        loaded={loaded}
        addingComment={addingComment}
      />
    </>
  );
}
