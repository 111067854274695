import React from "react";
import Canvas from "./canvas";

const getMax = (arr) => {
  let len = arr.length;
  let max = -Infinity;

  while (len--) {
    max = arr[len] > max ? arr[len] : max;
  }
  return max;
};

const formatTime = (time) => {
  const hours = Math.floor(time / 3600)
    .toFixed(0)
    .padStart(2, "0");
  const minutes = Math.floor((time / 60) % 60)
    .toFixed(0)
    .padStart(2, "0");
  const seconds = Math.floor(time % 60)
    .toFixed(0)
    .padStart(2, "0");
  const milliseconds = Math.round(time * 100)
    .toFixed(0)
    .padStart(2, "0")
    .slice(-2);
  if (hours > 0) {
    const time = hours + ":" + minutes + ":" + seconds + ":" + milliseconds;
    return time;
  } else {
    const time = minutes + ":" + seconds + ":" + milliseconds;
    return time;
  }
};

const getStartOpacity = (currentPercentage, mouseEntered, temporaryCursor) => {
  if (currentPercentage < 0.09 || (mouseEntered && temporaryCursor < 0.09)) {
    return 0.5;
  } else if (
    currentPercentage < 0.13 ||
    (mouseEntered && temporaryCursor < 0.13)
  ) {
    if (mouseEntered) {
      return (
        0.5 + (Math.min(currentPercentage, temporaryCursor) - 0.09) * 25 * 0.5
      );
    } else {
      return 0.5 + (currentPercentage - 0.09) * 25 * 0.5;
    }
  } else {
    return 1;
  }
};

const getEndOpacity = (currentPercentage, mouseEntered, temporaryCursor) => {
  if (currentPercentage > 0.91 || (mouseEntered && temporaryCursor > 0.91)) {
    return 0.5;
  } else if (
    currentPercentage > 0.86 ||
    (mouseEntered && temporaryCursor > 0.87)
  ) {
    return (
      0.5 +
      (1 - (Math.max(currentPercentage, temporaryCursor) - 0.87) * 25) * 0.5
    );
  } else {
    return 1;
  }
};

function CreateStaticCanvas({
  barsNo,
  waveformStart,
  waveformInterval,
  startTime,
  endTime,
  waveform,
  currentPercentage,
  temporaryCursor,
  mouseEntered,
  zoomPercent,
  commentList,
  canvasHeight,
  commentHovered,
  editingComment,
}) {
  const jsonData = waveform.data;

  const draw = (ctx) => {
    ctx.canvas.width = 3 * barsNo;
    ctx.canvas.height = canvasHeight;

    // Get the DPR and size of the canvas
    var dpr = window.devicePixelRatio;
    var rect = ctx.canvas.getBoundingClientRect();

    // Set the "actual" size of the canvas
    ctx.canvas.width = rect.width * dpr;
    ctx.canvas.height = rect.height * dpr;

    // Scale the context to ensure correct drawing operations
    ctx.scale(dpr, dpr);

    // Set the "drawn" size of the canvas
    ctx.canvas.style.width = `${rect.width}px`;
    ctx.canvas.style.height = `${rect.height}px`;

    const maxAmplitude = getMax(jsonData);
    //create a loop that iterates through jsonData and draws a rectangle for each data point, with an increment of step
    for (let i = 0; i < barsNo; i += 1) {
      const dataPoint =
        Math.abs(jsonData[Math.round(waveformStart + i * waveformInterval)]) /
        maxAmplitude;

      if (!commentHovered && !editingComment) {
        if (i / barsNo < currentPercentage) {
          ctx.fillStyle = "#D3DEE0";
        } else {
          ctx.fillStyle = "#333333";
        }
      } else if (editingComment) {
        if (
          i / barsNo > editingComment.x / (3 * barsNo) &&
          i / barsNo <
            editingComment.x / (3 * barsNo) +
              editingComment.width / (3 * barsNo)
        ) {
          if (i / barsNo < currentPercentage) {
            ctx.globalAlpha = 1;
            ctx.fillStyle = editingComment.colour;
          } else {
            ctx.globalAlpha = 0.5;
            ctx.fillStyle = editingComment.colour;
          }
        } else {
          ctx.globalAlpha = 1;
          ctx.fillStyle = "#333333";
        }
      } else {
        if (
          i / barsNo > commentHovered.x / (3 * barsNo) &&
          i / barsNo <
            commentHovered.x / (3 * barsNo) +
              commentHovered.width / (3 * barsNo)
        ) {
          ctx.fillStyle = commentHovered.colour;
        } else {
          ctx.fillStyle = "#333333";
        }
      }
      ctx.fillRect(
        0 + 3 * i,
        91 - zoomPercent * 8 - 27 * dataPoint,
        1.5,
        27 * dataPoint + zoomPercent * 8
      );
    }

    // function loops through findRowsNo from the end going backwards until it finds an array which is not empty. This is the last row of comments. Return the index of this row.
    const findLastRowNo = (arr) => {
      for (let i = arr.length - 1; i >= 0; i--) {
        if (arr[i].length !== 0) {
          return i;
        }
      }
    };

    //progress bar in middle of canvas
    const lastRowNo = findLastRowNo(commentList);
    ctx.fillStyle = "#D3DEE0";
    ctx.globalAlpha = 0.05;
    if (lastRowNo === 0 || lastRowNo === undefined) {
      ctx.fillRect(0, 97.3, 3 * barsNo - 1.5, 15 + 5);
    } else {
      ctx.fillRect(0, 97.3, 3 * barsNo - 1.5, 15 + 12.5 * lastRowNo + 5);
    }
    ctx.globalAlpha = 1;

    const findRowsNo = (row) => row.length === 0;
    const noCommentRows = commentList.findIndex(findRowsNo);

    //progress bar in middle of canvas
    ctx.fillStyle = "#D3DEE0";
    ctx.globalAlpha = 0.1;
    if (noCommentRows === 0) {
      ctx.fillRect(0, 97.3, 3 * barsNo * currentPercentage, 15 + 5);
    } else {
      ctx.fillRect(
        0,
        97.3,
        3 * barsNo * currentPercentage,
        15 + 12.5 * (noCommentRows - 1) + 5
      );
    }
    ctx.globalAlpha = 1;

    //timestamp of the start of the section displayed
    ctx.fillStyle = "#707070";
    ctx.globalAlpha = getStartOpacity(
      currentPercentage,
      mouseEntered,
      temporaryCursor
    );
    ctx.font = " 8px AeonikBold";
    ctx.textAlign = "left";
    if (startTime > 0) {
      ctx.fillText(formatTime(startTime) + " |", 0, 50 - zoomPercent * 6);
    } else {
      ctx.fillText(formatTime(0) + " |", 0, 50 - zoomPercent * 6);
    }
    ctx.globalAlpha = 1;

    //timestamp of the end of the section displayed
    ctx.globalAlpha = getEndOpacity(
      currentPercentage,
      mouseEntered,
      temporaryCursor
    );
    ctx.fillStyle = "#707070";
    ctx.font = " 8px AeonikBold";
    ctx.textAlign = "right";
    ctx.fillText("| " + formatTime(endTime), 3 * barsNo, 50 - zoomPercent * 6);
    ctx.globalAlpha = 1;
  };

  return <Canvas draw={draw} />;
}

export default CreateStaticCanvas;
