import { useState, useMemo } from "react";
import CreateCanvas from "./canvas/app4canvas";
import CreateStaticCanvas from "./canvas/staticCanvas";
import CreateProgressCanvas from "./canvas/progressCanvas";
import CreateMarkersCanvas from "./canvas/markersCanvas";
import transparent from "../images/transparent.svg";

export default function CanvasElements({
  setMousePositionX,
  barsNo,
  startTime,
  endTime,
  waveform,
  currentPercentage,
  mousePositionX,
  zoom,
  maxZoom,
  currentTime,
  commentList,
  duration,
  commentStartTime,
  commentEndTime,
  waveformLength,
  loaded,
  addingComment,
  setAudioTime,
  setCommentEndTime,
  setCommentStartTime,
  editingComment,
  setEditingComment,
}) {
  // Constants based on screen size
  const canvasStartX = 332.5;
  const canvasStartY = 246;
  const canvasLength = 3 * barsNo;
  const canvasHeight = 270;

  // Transparent image (for dragging)
  const img = new Image();
  img.src = transparent;

  const findComment = (selectedComment) => {
    for (let i = 0; i < commentList.length; i++) {
      for (let j = 0; j < commentList[i].length; j++) {
        if (commentList[i][j].CurrentTime === selectedComment.id) {
          return commentList[i][j];
        }
      }
    }
  };

  const comment = findComment(editingComment);

  const [mouseEntered, setMouseEntered] = useState(false);
  const [commentHitRegions, setCommentHitRegions] = useState([]);
  const [commentHovered, setCommentHovered] = useState(false);
  const [playheadHoverRegion, setPlayheadHoverRegion] = useState(null);
  const [dragHover, setDragHover] = useState(null);

  //handle mouse clicks on the waveform whilst adding a comment, either set the comment start time or comment end time if start time has already been set
  const handleCanvasClick = (e) => {
    const pos = {
      x: e.clientX - canvasStartX,
      y: e.clientY - canvasStartY,
    };
    if (loaded && editingComment) {
      if (findHitRegion(commentHitRegions, pos) === null) {
        setEditingComment(false);
      } else if (
        findHitRegion(commentHitRegions, pos).id === editingComment.id
      ) {
        console.log("clicked on comment");
        setEditingComment(false);
      }
    } else if (loaded && addingComment) {
      const newTime =
        (mousePositionX / canvasLength) * (endTime - startTime) + startTime;
      if (commentStartTime === null) {
        setCommentStartTime(newTime);
      } else if (commentEndTime === null && newTime > commentStartTime) {
        setCommentEndTime(newTime);
      }
    } else if (findHitRegion(commentHitRegions, pos)) {
      console.log(
        "clicked on comment region, id: ",
        findHitRegion(commentHitRegions, pos).id
      );
      console.log(findHitRegion(commentHitRegions, pos));
      setEditingComment({
        id: findHitRegion(commentHitRegions, pos).id,
        x: findHitRegion(commentHitRegions, pos).x,
        width: findHitRegion(commentHitRegions, pos).width,
        colour: findHitRegion(commentHitRegions, pos).colour,
      });
    }
  };

  // Handle a drag on the canvas
  const handleDrag = (e) => {
    if (loaded) {
      const pos = {
        x: e.clientX - canvasStartX,
        y: e.clientY - canvasStartY,
      };
      if (dragHover === "start") {
        const endPos =
          (3 * barsNo * (commentEndTime - startTime)) / (endTime - startTime);
        if (pos.x > 0 && pos.x < endPos) {
          setCommentStartTime(
            (pos.x / canvasLength) * (endTime - startTime) + startTime
          );
        }
      } else if (dragHover === "end") {
        const startPos =
          (3 * barsNo * (commentStartTime - startTime)) / (endTime - startTime);
        if (pos.x < canvasLength && pos.x > startPos) {
          setCommentEndTime(
            (pos.x / canvasLength) * (endTime - startTime) + startTime
          );
        }
      } else if (!addingComment || (addingComment && commentEndTime !== null)) {
        if (pos.x > 0 && pos.x < canvasLength) {
          setAudioTime(
            (mousePositionX / canvasLength) * (endTime - startTime) + startTime
          );
          setMousePositionX(pos.x);
        } else if (pos.x < 0 && e.clientX !== 0) {
          setAudioTime(startTime);
          setMousePositionX(canvasStartX);
        } else if (pos.x > canvasLength) {
          setAudioTime(endTime);
          setMousePositionX(canvasStartX + canvasLength);
        }
      }
    }
  };

  const handleDragStart = (e) => {
    e.dataTransfer.setDragImage(img, 0, 0);
  };

  // return if the cursor x and y are within the region. The region is a rectangle
  function isIntersect(cursor, region) {
    return (
      cursor.x > region.x &&
      cursor.x < region.x + region.width &&
      cursor.y > region.y &&
      cursor.y < region.y + region.height
    );
  }

  const findHitRegion = (array, cursor) => {
    // find the hit region that the cursor is in
    for (let i = 0; i < array.length; i++) {
      if (isIntersect(cursor, array[i])) {
        return array[i];
      }
    }
    return null;
  };

  const canvasStyle = useMemo(() => {
    if (dragHover) {
      return {
        cursor: "col-resize",
      };
    } else {
      return {
        cursor: "default",
      };
    }
  }, [dragHover]);

  const intersectsCommentEdge = (cursor, type) => {
    const commentPos = (type - startTime) / (endTime - startTime);
    const findRowsNo = (row) => row.length === 0;
    const noCommentRows = commentList.findIndex(findRowsNo);
    // check if the cursor is over the start or end of the comment
    const region = {
      x: 3 * barsNo * commentPos - 7.5,
      y: 55,
      width: 15,
      height: 100 + 12.5 * (noCommentRows - 1),
    };
    if (isIntersect(cursor, region)) {
      return true;
    } else {
      return false;
    }
  };

  const handleMouseMove = (e) => {
    if (loaded) {
      const pos = {
        x: e.clientX - canvasStartX,
        y: e.clientY - canvasStartY,
      };
      if (!addingComment) {
        if (isIntersect(pos, playheadHoverRegion)) {
          setCommentHovered(false);
        } else if (findHitRegion(commentHitRegions, pos)) {
          setCommentHovered({
            id: findHitRegion(commentHitRegions, pos).id,
            x: findHitRegion(commentHitRegions, pos).x,
            width: findHitRegion(commentHitRegions, pos).width,
            colour: findHitRegion(commentHitRegions, pos).colour,
          });
        } else {
          setCommentHovered(false);
        }
        setDragHover(null);
      } else if (editingComment) {
        if (intersectsCommentEdge(pos, comment.StartTime)) {
          setDragHover("start");
        } else if (intersectsCommentEdge(pos, comment.EndTime)) {
          setDragHover("end");
        } else {
          setDragHover(null);
        }
      } else if (commentEndTime !== null) {
        if (intersectsCommentEdge(pos, commentStartTime)) {
          setDragHover("start");
        } else if (intersectsCommentEdge(pos, commentEndTime)) {
          setDragHover("end");
        } else {
          setDragHover(null);
        }
      }

      setMousePositionX(pos.x);
    }
  };

  return (
    <div
      id="canvas-element"
      onMouseEnter={() => setMouseEntered(true)}
      onMouseLeave={() => setMouseEntered(false)}
      onClick={(e) => handleCanvasClick(e)}
      onMouseMove={(e) => handleMouseMove(e)}
      draggable="true"
      onDragStart={(e) => handleDragStart(e)}
      onDrag={(e) => handleDrag(e)}
      style={canvasStyle}
    >
      <div id="canvas-containers">
        <CreateStaticCanvas
          barsNo={barsNo}
          waveformStart={useMemo(() => {
            return (startTime / duration) * waveformLength;
          }, [startTime, duration, waveformLength])}
          waveformInterval={useMemo(() => {
            return (Math.pow(2, 1 - zoom) * waveformLength) / barsNo;
          }, [zoom, waveformLength, barsNo])}
          startTime={startTime}
          endTime={endTime}
          waveform={waveform}
          currentPercentage={currentPercentage}
          temporaryCursor={mousePositionX / (3 * barsNo)}
          zoomPercent={zoom / maxZoom}
          commentList={commentList}
          canvasHeight={canvasHeight}
          commentHovered={commentHovered}
          editingComment={editingComment}
        />
        <CreateMarkersCanvas
          barsNo={barsNo}
          markers={commentList}
          duration={duration}
          startTime={startTime}
          endTime={endTime}
          canvasHeight={canvasHeight}
          setCommentHitRegions={setCommentHitRegions}
          commentHitRegions={commentHitRegions}
          commentHovered={commentHovered.id}
          editingComment={editingComment}
        />
        <CreateProgressCanvas
          barsNo={barsNo}
          currentPercentage={currentPercentage}
          currentTime={currentTime}
          temporaryCursor={mousePositionX / (3 * barsNo)}
          zoomPercent={zoom / maxZoom}
          commentList={commentList}
          canvasHeight={canvasHeight}
          setPlayheadHoverRegion={setPlayheadHoverRegion}
          playheadHoverRegion={playheadHoverRegion}
          addingComment={addingComment}
          editingComment={editingComment}
        />
        <CreateCanvas
          barsNo={barsNo}
          markers={commentList}
          temporaryCursor={mousePositionX}
          mouseEntered={mouseEntered}
          duration={duration}
          commentStartTime={commentStartTime}
          commentEndTime={commentEndTime}
          zoomStartTime={startTime}
          zoomEndTime={endTime}
          zoomPercent={zoom / maxZoom}
          canvasHeight={canvasHeight}
          addingComment={addingComment}
          canvasStartX={canvasStartX}
          canvasWidth={canvasLength}
          currentTime={currentTime}
          canvasLength={canvasLength}
          startTime={startTime}
          endTime={endTime}
          commentList={commentList}
          editingComment={editingComment}
        />
      </div>
    </div>
  );
}
