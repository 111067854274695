import React, { useState } from "react";
import checkIfLoaded from "./checkIfLoaded";

export default function PlaybackSpeedControls({
  playbackSpeed,
  setPlaybackSpeed,
  loaded,
}) {
  const playbackSpeeds = [0.25, 0.5, 1, 1.5, 2];
  const [showPlaybackSpeedOptions, togglePlaybackSpeedOptions] =
    useState(false);

  return (
    <div
      id="playback-speed-container"
      onMouseLeave={() =>
        checkIfLoaded(togglePlaybackSpeedOptions, loaded, false)
      }
    >
      {showPlaybackSpeedOptions && (
        <div id="playbackOptions">
          {playbackSpeeds.map((speed) => {
            return (
              <button
                className="playbackButton"
                onClick={() => setPlaybackSpeed(speed)}
                key={speed}
              >
                {speed}x
              </button>
            );
          })}
        </div>
      )}
      <button
        id="selectedPlaybackButton"
        onMouseEnter={() =>
          checkIfLoaded(togglePlaybackSpeedOptions, loaded, true)
        }
      >
        {playbackSpeed}x
      </button>
    </div>
  );
}
