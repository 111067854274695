import zoomOut from "../images/zoom-out.svg";
import zoomIn from "../images/zoom-in.svg";

export default function ZoomButtons({ loaded, zoom, setNewZoom, maxZoom }) {
  const handleZoomIn = () => {
    if (loaded && zoom < maxZoom) {
      if (zoom - 0.8 * maxZoom > 0.2) {
        setNewZoom(Math.pow(2, 1 - maxZoom), maxZoom);
      } else {
        setNewZoom(
          Math.pow(2, -zoom - 0.2 * (maxZoom - 1) + 1),
          zoom + 0.2 * (maxZoom - 1)
        );
      }
    }
  };

  const handleZoomOut = () => {
    if (loaded && zoom > 1) {
      if (zoom - 0.2 * maxZoom < 0.8) {
        setNewZoom(1, 1);
      } else {
        setNewZoom(
          Math.pow(2, 0.8 - zoom + 0.2 * maxZoom),
          zoom - 0.2 * (maxZoom - 1)
        );
      }
    }
  };

  return (
    <>
      <button onClick={handleZoomOut} className="buttonNoStyle">
        <img src={zoomOut} alt="Zoom out" id="zoomOutButton" />
      </button>
      <button onClick={handleZoomIn} className="buttonNoStyle">
        <img src={zoomIn} alt="Zoom in" id="zoomInButton" />
      </button>
    </>
  );
}
