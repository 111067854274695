import { useState, useMemo, useEffect } from "react";
import playSVG from "../images/play.svg";
import pauseSVG from "../images/pause.svg";
import loopOn from "../images/loop-on.svg";
import loopOff from "../images/loop-off.svg";
import setToBeginning from "../images/setToBeginning.svg";

export default function CommentMediaControls({
  addingComment,
  commentStartTime,
  commentEndTime,
  startTime,
  endTime,
  barsNo,
  isCommentPlaying,
  toggleIsCommentPlaying,
  setAudioTime,
  currentTime,
  editingComment,
  commentList,
}) {
  const findComment = (selectedComment) => {
    for (let i = 0; i < commentList.length; i++) {
      for (let j = 0; j < commentList[i].length; j++) {
        if (commentList[i][j].CurrentTime === selectedComment.id) {
          return commentList[i][j];
        }
      }
    }
  };

  const comment = findComment(editingComment);

  const [loop, setLoop] = useState(false);
  const canvasStartX = 332.5;
  const canvasLength = 3 * barsNo;
  const divStyle = (commentStartTime, commentEndTime) => {
    return {
      left:
        canvasStartX +
        (((commentStartTime + commentEndTime) / 2 - startTime) /
          (endTime - startTime)) *
          canvasLength -
        32.5 +
        "px",
    };
  };

  useEffect(() => {
    if (!addingComment) {
      setLoop(false);
    }
  }, [addingComment]);

  const playButtonSVG = useMemo(() => {
    if (isCommentPlaying) {
      console.log(isCommentPlaying);
      return pauseSVG;
    } else {
      return playSVG;
    }
  }, [isCommentPlaying]);

  const loopButtonSVG = useMemo(() => {
    if (loop) {
      return loopOn;
    } else {
      return loopOff;
    }
  }, [loop]);

  useEffect(() => {
    if (addingComment) {
      if (loop) {
        if (currentTime >= commentEndTime) {
          setAudioTime(commentStartTime);
        }
      }
    } else if (editingComment) {
      if (loop) {
        if (currentTime >= comment.EndTime) {
          setAudioTime(comment.StartTime);
        }
      }
    }
  }, [
    currentTime,
    commentStartTime,
    commentEndTime,
    loop,
    setAudioTime,
    addingComment,
    editingComment,
    comment,
  ]);

  if (editingComment) {
    return (
      <div
        id="commentMediaControls"
        style={divStyle(comment.StartTime, comment.EndTime)}
      >
        <button
          id="setToStartButton"
          className="buttonNoStyle"
          onClick={() => {
            setAudioTime(comment.StartTime);
            toggleIsCommentPlaying(true);
          }}
        >
          <img src={setToBeginning} alt="set to beginning of comment" />
        </button>
        <button
          id="commentPlayButton"
          className="buttonNoStyle"
          onClick={() => toggleIsCommentPlaying(!isCommentPlaying)}
        >
          <img src={playButtonSVG} alt="play/pause" />
        </button>
        <button
          id="loopButton"
          className="buttonNoStyle"
          onClick={() => setLoop(!loop)}
        >
          <img src={loopButtonSVG} alt="loop" />
        </button>
      </div>
    );
  } else if (addingComment && commentEndTime !== null) {
    return (
      <div
        id="commentMediaControls"
        style={divStyle(commentStartTime, commentEndTime)}
      >
        <button
          id="setToStartButton"
          className="buttonNoStyle"
          onClick={() => {
            setAudioTime(commentStartTime);
            toggleIsCommentPlaying(true);
          }}
        >
          <img src={setToBeginning} alt="set to beginning of comment" />
        </button>
        <button
          id="commentPlayButton"
          className="buttonNoStyle"
          onClick={() => toggleIsCommentPlaying(!isCommentPlaying)}
        >
          <img src={playButtonSVG} alt="play/pause" />
        </button>
        <button
          id="loopButton"
          className="buttonNoStyle"
          onClick={() => setLoop(!loop)}
        >
          <img src={loopButtonSVG} alt="loop" />
        </button>
      </div>
    );
  }
}
