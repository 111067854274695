import React from "react";
import Canvas from "./canvas";

function CreateCanvas({
  barsNo,
  temporaryCursor,
  mouseEntered,
  duration,
  commentStartTime,
  commentEndTime,
  zoomStartTime,
  zoomEndTime,
  zoomPercent,
  canvasHeight,
  addingComment,
  canvasStart,
  canvasWidth,
  setDefaultStartCursor,
  currentTime,
  canvasLength,
  startTime,
  endTime,
  commentList,
  editingComment,
}) {
  const draw = (ctx) => {
    ctx.canvas.width = 3 * barsNo;
    ctx.canvas.height = canvasHeight;

    // Get the DPR and size of the canvas
    var dpr = window.devicePixelRatio;
    var rect = ctx.canvas.getBoundingClientRect();

    // Set the "actual" size of the canvas
    ctx.canvas.width = rect.width * dpr;
    ctx.canvas.height = rect.height * dpr;

    // Scale the context to ensure correct drawing operations
    ctx.scale(dpr, dpr);

    // Set the "drawn" size of the canvas
    ctx.canvas.style.width = `${rect.width}px`;
    ctx.canvas.style.height = `${rect.height}px`;

    const findRowsNo = (row) => row.length === 0;
    const noCommentRows = commentList.findIndex(findRowsNo);

    const temporaryTime =
      (temporaryCursor / (3 * barsNo)) * (zoomEndTime - zoomStartTime) +
      zoomStartTime;
    const temporaryPercentage = temporaryCursor / (3 * barsNo);

    const roundRect = (
      ctx,
      x,
      y,
      width,
      height,
      radius = 5,
      fill = false,
      stroke = true
    ) => {
      if (typeof radius === "number") {
        radius = { tl: radius, tr: radius, br: radius, bl: radius };
      } else {
        radius = { ...{ tl: 0, tr: 0, br: 0, bl: 0 }, ...radius };
      }
      ctx.beginPath();
      ctx.moveTo(x + radius.tl, y);
      ctx.lineTo(x + width - radius.tr, y);
      ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
      ctx.lineTo(x + width, y + height - radius.br);
      ctx.quadraticCurveTo(
        x + width,
        y + height,
        x + width - radius.br,
        y + height
      );
      ctx.lineTo(x + radius.bl, y + height);
      ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
      ctx.lineTo(x, y + radius.tl);
      ctx.quadraticCurveTo(x, y, x + radius.tl, y);
      ctx.closePath();
      if (fill) {
        ctx.fill();
      }
      if (stroke) {
        ctx.stroke();
      }
    };

    const minutes = (time) =>
      Math.floor((time / 60) % 60)
        .toFixed(0)
        .padStart(2, "0");
    const seconds = (time) =>
      Math.floor(time % 60)
        .toFixed(0)
        .padStart(2, "0");
    const milliseconds = (time) =>
      Math.floor(time * 100)
        .toFixed(0)
        .padStart(2, "0")
        .slice(-2);

    ctx.font = " 8px AeonikBold";
    if (addingComment) {
      if (commentStartTime === null) {
        if (mouseEntered) {
          // select start time
          ctx.strokeStyle = "#C2CCCE";
          ctx.lineWidth = 1;
          ctx.beginPath();
          ctx.setLineDash([2, 2]);
          ctx.moveTo(temporaryCursor - 0.5, 0);
          ctx.lineTo(temporaryCursor - 0.5, canvasHeight);
          ctx.stroke();

          if (temporaryPercentage < 0.03) {
            ctx.fillStyle = "#0A0A0A";
            ctx.fillRect(0, 40 - zoomPercent * 6, 42, 15);
            ctx.fillStyle = "#C2CCCE";
            ctx.textAlign = "left";
            ctx.fillText("Choose a *Start* Point", 0, 37 - zoomPercent * 6);
            ctx.textAlign = "center";
            ctx.fillText(minutes(temporaryTime) + ":", 7, 50 - zoomPercent * 6);
            ctx.fillText(
              seconds(temporaryTime) + ".",
              19,
              50 - zoomPercent * 6
            );
            ctx.fillText(
              milliseconds(temporaryTime),
              19 + 11,
              50 - zoomPercent * 6
            );
            ctx.fillText("| ", 39, 50 - zoomPercent * 6);
          } else if (temporaryPercentage > 0.975) {
            ctx.fillStyle = "#0A0A0A";
            ctx.fillRect(3 * barsNo - 44, 40 - zoomPercent * 6, 44, 15);
            ctx.fillStyle = "#C2CCCE";
            ctx.textAlign = "right";
            ctx.fillText(
              "Choose a *Start* Point",
              3 * barsNo,
              37 - zoomPercent * 6
            );
            ctx.textAlign = "center";
            ctx.fillText("| ", -17 - 20 + 3 * barsNo, 50 - zoomPercent * 6);
            ctx.fillText(
              minutes(temporaryTime) + ":",
              -17 - 12 + 3 * barsNo,
              50 - zoomPercent * 6
            );
            ctx.fillText(
              seconds(temporaryTime) + ".",
              -17 + 3 * barsNo,
              50 - zoomPercent * 6
            );
            ctx.fillText(
              milliseconds(temporaryTime),
              -17 + 11 + 3 * barsNo,
              50 - zoomPercent * 6
            );
          } else {
            ctx.fillStyle = "#0A0A0A";
            ctx.fillRect(
              3 * barsNo * temporaryPercentage - 22.5,
              25 - zoomPercent * 6,
              44,
              32
            );
            ctx.fillStyle = "#C2CCCE";
            if (temporaryPercentage > 0.935) {
              ctx.textAlign = "right";
              ctx.fillText(
                "Choose a *Start* Point",
                3 * barsNo,
                37 - zoomPercent * 6
              );
            } else if (temporaryPercentage < 0.065) {
              ctx.textAlign = "left";
              ctx.fillText("Choose a *Start* Point", 0, 37 - zoomPercent * 6);
            } else {
              ctx.textAlign = "center";
              ctx.fillText(
                "Choose a *Start* Point",
                3 * barsNo * temporaryPercentage,
                37 - zoomPercent * 6
              );
            }
            ctx.textAlign = "center";
            ctx.fillText(
              "| ",
              -20 + 3 * barsNo * temporaryPercentage,
              50 - zoomPercent * 6
            );
            ctx.fillText(
              minutes(temporaryTime) + ":",
              -12 + 3 * barsNo * temporaryPercentage,
              50 - zoomPercent * 6
            );
            ctx.fillText(
              seconds(temporaryTime) + ".",
              0 + 3 * barsNo * temporaryPercentage,
              50 - zoomPercent * 6
            );
            ctx.fillText(
              milliseconds(temporaryTime),
              11 + 3 * barsNo * temporaryPercentage,
              50 - zoomPercent * 6
            );
            ctx.fillText(
              "| ",
              20 + 3 * barsNo * temporaryPercentage,
              50 - zoomPercent * 6
            );
          }
        } else {
          // set start time to default
          const cursorPercentage =
            (currentTime - startTime) / (endTime - startTime);
          ctx.strokeStyle = "#C2CCCE";
          ctx.lineWidth = 1;
          ctx.beginPath();
          ctx.setLineDash([2, 2]);
          ctx.moveTo(3 * barsNo * cursorPercentage - 0.5, 0);
          ctx.lineTo(3 * barsNo * cursorPercentage - 0.5, canvasHeight);
          ctx.stroke();
          ctx.fillStyle = "#0A0A0A";
          ctx.fillRect(
            3 * barsNo * cursorPercentage - 22.5,
            25 - zoomPercent * 6,
            44,
            32
          );
          ctx.fillStyle = "#C2CCCE";
          ctx.textAlign = "center";
          ctx.fillText(
            "Choose a *Start* Point",
            3 * barsNo * cursorPercentage,
            37 - zoomPercent * 6
          );
          ctx.fillText(
            "| ",
            -20 + 3 * barsNo * cursorPercentage,
            50 - zoomPercent * 6
          );
          ctx.fillText(
            minutes(currentTime) + ":",
            -12 + 3 * barsNo * cursorPercentage,
            50 - zoomPercent * 6
          );
          ctx.fillText(
            seconds(currentTime) + ".",
            0 + 3 * barsNo * cursorPercentage,
            50 - zoomPercent * 6
          );
          ctx.fillText(
            milliseconds(currentTime),
            11 + 3 * barsNo * cursorPercentage,
            50 - zoomPercent * 6
          );
          ctx.fillText(
            "| ",
            20 + 3 * barsNo * cursorPercentage,
            50 - zoomPercent * 6
          );
        }
      } else if (commentEndTime === null) {
        // select end time
        const commentStartPos =
          (commentStartTime - startTime) / (endTime - startTime);
        if (commentStartPos > 0.975) {
          ctx.fillStyle = "#0A0A0A";
          ctx.fillRect(3 * barsNo - 44, 40 - zoomPercent * 6, 44, 15);
          ctx.fillStyle = "#FFB500";
          ctx.textAlign = "center";
          ctx.globalAlpha = 0.5;
          ctx.fillText("| ", -17 - 20 + 3 * barsNo, 50 - zoomPercent * 6);
          ctx.globalAlpha = 1;
          ctx.fillText(
            minutes(commentStartTime) + ":",
            -17 - 12 + 3 * barsNo,
            50 - zoomPercent * 6
          );
          ctx.fillText(
            seconds(commentStartTime) + ".",
            -17 + 3 * barsNo,
            50 - zoomPercent * 6
          );
          ctx.fillText(
            milliseconds(commentStartTime),
            -17 + 11 + 3 * barsNo,
            50 - zoomPercent * 6
          );
        } else if (commentStartPos < 0.025) {
          ctx.fillStyle = "#0A0A0A";
          ctx.fillRect(0, 40 - zoomPercent * 6, 42, 15);
          ctx.fillStyle = "#FFB500";
          ctx.textAlign = "center";
          ctx.fillText(
            minutes(commentStartTime) + ":",
            7,
            50 - zoomPercent * 6
          );
          ctx.fillText(
            seconds(commentStartTime) + ".",
            19,
            50 - zoomPercent * 6
          );
          ctx.fillText(
            milliseconds(commentStartTime),
            19 + 11,
            50 - zoomPercent * 6
          );
          ctx.globalAlpha = 0.5;
          ctx.fillText("| ", 39, 50 - zoomPercent * 6);
          ctx.globalAlpha = 1;
        } else {
          ctx.fillStyle = "#0A0A0A";
          ctx.fillRect(
            3 * barsNo * commentStartPos - 22.5,
            25 - zoomPercent * 6,
            44,
            32
          );
          ctx.fillStyle = "#FFB500";
          ctx.textAlign = "center";
          ctx.globalAlpha = 0.5;
          ctx.fillText(
            "| ",
            -20 + 3 * barsNo * commentStartPos,
            50 - zoomPercent * 6
          );
          ctx.globalAlpha = 1;
          ctx.fillText(
            minutes(commentStartTime) + ":",
            -12 + 3 * barsNo * commentStartPos,
            50 - zoomPercent * 6
          );
          ctx.fillText(
            seconds(commentStartTime) + ".",
            0 + 3 * barsNo * commentStartPos,
            50 - zoomPercent * 6
          );
          ctx.fillText(
            milliseconds(commentStartTime),
            11 + 3 * barsNo * commentStartPos,
            50 - zoomPercent * 6
          );
          ctx.globalAlpha = 0.5;
          ctx.fillText(
            "| ",
            20 + 3 * barsNo * commentStartPos,
            50 - zoomPercent * 6
          );
          ctx.globalAlpha = 1;
        }

        // ctx.strokeStyle = "rgb(255, 0, 0)";
        ctx.fillStyle = "#FFB500";
        roundRect(
          ctx,
          3 * barsNo * commentStartPos - 1.25,
          55,
          2.5,
          100 + 12.5 * (noCommentRows - 1),
          1.25,
          true,
          false
        );

        // NEW SECTION!!!
        // ********************
        // ********************

        if (mouseEntered && temporaryPercentage > commentStartPos) {
          // select start time
          ctx.strokeStyle = "#C2CCCE";
          ctx.lineWidth = 1;
          ctx.beginPath();
          ctx.setLineDash([2, 2]);
          ctx.moveTo(temporaryCursor - 0.5, 0);
          ctx.lineTo(temporaryCursor - 0.5, canvasHeight);
          ctx.stroke();

          if (temporaryPercentage < 0.03) {
            ctx.fillStyle = "#0A0A0A";
            ctx.fillRect(0, 40 - zoomPercent * 6, 42, 15);
            ctx.fillStyle = "#C2CCCE";
            ctx.textAlign = "left";
            ctx.fillText("Choose a *Start* Point", 0, 37 - zoomPercent * 6);
            ctx.textAlign = "center";
            ctx.fillText(minutes(temporaryTime) + ":", 7, 50 - zoomPercent * 6);
            ctx.fillText(
              seconds(temporaryTime) + ".",
              19,
              50 - zoomPercent * 6
            );
            ctx.fillText(
              milliseconds(temporaryTime),
              19 + 11,
              50 - zoomPercent * 6
            );
            ctx.fillText("| ", 39, 50 - zoomPercent * 6);
          } else if (temporaryPercentage > 0.975) {
            ctx.fillStyle = "#0A0A0A";
            ctx.fillRect(3 * barsNo - 44, 40 - zoomPercent * 6, 44, 15);
            ctx.fillStyle = "#C2CCCE";
            ctx.textAlign = "right";
            ctx.fillText(
              "Choose an *End* Point",
              3 * barsNo,
              37 - zoomPercent * 6
            );
            ctx.textAlign = "center";
            ctx.fillText("| ", -17 - 20 + 3 * barsNo, 50 - zoomPercent * 6);
            ctx.fillText(
              minutes(temporaryTime) + ":",
              -17 - 12 + 3 * barsNo,
              50 - zoomPercent * 6
            );
            ctx.fillText(
              seconds(temporaryTime) + ".",
              -17 + 3 * barsNo,
              50 - zoomPercent * 6
            );
            ctx.fillText(
              milliseconds(temporaryTime),
              -17 + 11 + 3 * barsNo,
              50 - zoomPercent * 6
            );
          } else {
            ctx.fillStyle = "#0A0A0A";
            ctx.fillRect(
              3 * barsNo * temporaryPercentage - 22.5,
              25 - zoomPercent * 6,
              44,
              32
            );
            ctx.fillStyle = "#C2CCCE";
            if (temporaryPercentage > 0.935) {
              ctx.textAlign = "right";
              ctx.fillText(
                "Choose an *End* Point",
                3 * barsNo,
                37 - zoomPercent * 6
              );
            } else if (temporaryPercentage < 0.065) {
              ctx.textAlign = "left";
              ctx.fillText("Choose a *Start* Point", 0, 37 - zoomPercent * 6);
            } else {
              ctx.textAlign = "center";
              ctx.fillText(
                "Choose an *End* Point",
                3 * barsNo * temporaryPercentage,
                37 - zoomPercent * 6
              );
            }
            ctx.textAlign = "center";
            ctx.fillText(
              "| ",
              -20 + 3 * barsNo * temporaryPercentage,
              50 - zoomPercent * 6
            );
            ctx.fillText(
              minutes(temporaryTime) + ":",
              -12 + 3 * barsNo * temporaryPercentage,
              50 - zoomPercent * 6
            );
            ctx.fillText(
              seconds(temporaryTime) + ".",
              0 + 3 * barsNo * temporaryPercentage,
              50 - zoomPercent * 6
            );
            ctx.fillText(
              milliseconds(temporaryTime),
              11 + 3 * barsNo * temporaryPercentage,
              50 - zoomPercent * 6
            );
            ctx.fillText(
              "| ",
              20 + 3 * barsNo * temporaryPercentage,
              50 - zoomPercent * 6
            );
          }
        } else {
          // set start time to default
          ctx.strokeStyle = "#C2CCCE";
          ctx.lineWidth = 1;
          ctx.beginPath();
          ctx.setLineDash([2, 2]);
          ctx.moveTo(3 * barsNo * commentStartPos - 0.5, 0);
          ctx.lineTo(3 * barsNo * commentStartPos - 0.5, canvasHeight);
          ctx.stroke();
          ctx.fillStyle = "#0A0A0A";
          ctx.fillRect(
            3 * barsNo * commentStartPos - 22.5,
            25 - zoomPercent * 6,
            44,
            32
          );
          ctx.fillStyle = "#C2CCCE";
          ctx.textAlign = "center";
          ctx.fillText(
            "Choose an *End* Point",
            3 * barsNo * commentStartPos,
            37 - zoomPercent * 6
          );
          ctx.fillText(
            "| ",
            -20 + 3 * barsNo * commentStartPos,
            50 - zoomPercent * 6
          );
          ctx.fillText(
            minutes(commentStartTime) + ":",
            -12 + 3 * barsNo * commentStartPos,
            50 - zoomPercent * 6
          );
          ctx.fillText(
            seconds(commentStartTime) + ".",
            0 + 3 * barsNo * commentStartPos,
            50 - zoomPercent * 6
          );
          ctx.fillText(
            milliseconds(commentStartTime),
            11 + 3 * barsNo * commentStartPos,
            50 - zoomPercent * 6
          );
          ctx.fillText(
            "| ",
            20 + 3 * barsNo * commentStartPos,
            50 - zoomPercent * 6
          );
        }

        // ********************
        // ********************
        // END OF NEW SECTION!!!
      } else {
        // start and end time set

        const commentStartPos =
          (commentStartTime - startTime) / (endTime - startTime);

        const commentEndPos =
          (commentEndTime - startTime) / (endTime - startTime);

        ctx.globalAlpha = 0.15;
        ctx.fillStyle = "#FFB500";
        ctx.fillRect(
          3 * barsNo * commentStartPos,
          55,
          3 * barsNo * (commentEndPos - commentStartPos),
          100 + 12.5 * (noCommentRows - 1)
        );
        ctx.globalAlpha = 1;

        if (commentStartPos > 0.975) {
          ctx.fillStyle = "#0A0A0A";
          ctx.fillRect(3 * barsNo - 44, 40 - zoomPercent * 6, 44, 15);
          ctx.fillStyle = "#FFB500";
          ctx.textAlign = "center";
          ctx.globalAlpha = 0.5;
          ctx.fillText("| ", -17 - 20 + 3 * barsNo, 50 - zoomPercent * 6);
          ctx.globalAlpha = 1;
          ctx.fillText(
            minutes(commentStartTime) + ":",
            -17 - 12 + 3 * barsNo,
            50 - zoomPercent * 6
          );
          ctx.fillText(
            seconds(commentStartTime) + ".",
            -17 + 3 * barsNo,
            50 - zoomPercent * 6
          );
          ctx.fillText(
            milliseconds(commentStartTime),
            -17 + 11 + 3 * barsNo,
            50 - zoomPercent * 6
          );
        } else if (commentStartPos < 0.025) {
          ctx.fillStyle = "#0A0A0A";
          ctx.fillRect(0, 40 - zoomPercent * 6, 42, 15);
          ctx.fillStyle = "#FFB500";
          ctx.textAlign = "center";
          ctx.fillText(
            minutes(commentStartTime) + ":",
            7,
            50 - zoomPercent * 6
          );
          ctx.fillText(
            seconds(commentStartTime) + ".",
            19,
            50 - zoomPercent * 6
          );
          ctx.fillText(
            milliseconds(commentStartTime),
            19 + 11,
            50 - zoomPercent * 6
          );
          ctx.globalAlpha = 0.5;
          ctx.fillText("| ", 39, 50 - zoomPercent * 6);
          ctx.globalAlpha = 1;
        } else {
          ctx.fillStyle = "#FFB500";
          ctx.textAlign = "center";
          ctx.globalAlpha = 0.5;
          ctx.fillText(
            "| ",
            -43 + (3 * barsNo * (commentStartPos + commentEndPos)) / 2,
            173 + 12.5 * (noCommentRows - 1)
          );
          ctx.globalAlpha = 1;
          ctx.fillText(
            minutes(commentStartTime) + ":",
            -35 + (3 * barsNo * (commentStartPos + commentEndPos)) / 2,
            173 + 12.5 * (noCommentRows - 1)
          );
          ctx.fillText(
            seconds(commentStartTime) + ".",
            -23 + (3 * barsNo * (commentStartPos + commentEndPos)) / 2,
            173 + 12.5 * (noCommentRows - 1)
          );
          ctx.fillText(
            milliseconds(commentStartTime),
            -12 + (3 * barsNo * (commentStartPos + commentEndPos)) / 2,
            173 + 12.5 * (noCommentRows - 1)
          );
          ctx.fillText(
            "-",
            0 + (3 * barsNo * (commentStartPos + commentEndPos)) / 2,
            173 + 12.5 * (noCommentRows - 1)
          );
          ctx.fillText(
            minutes(commentEndTime) + ":",
            13 + (3 * barsNo * (commentStartPos + commentEndPos)) / 2,
            173 + 12.5 * (noCommentRows - 1)
          );
          ctx.fillText(
            seconds(commentEndTime) + ".",
            24 + (3 * barsNo * (commentStartPos + commentEndPos)) / 2,
            173 + 12.5 * (noCommentRows - 1)
          );
          ctx.fillText(
            milliseconds(commentEndTime),
            36 + (3 * barsNo * (commentStartPos + commentEndPos)) / 2,
            173 + 12.5 * (noCommentRows - 1)
          );
          ctx.globalAlpha = 0.5;
          ctx.fillText(
            "| ",
            44 + (3 * barsNo * (commentStartPos + commentEndPos)) / 2,
            173 + 12.5 * (noCommentRows - 1)
          );
          ctx.globalAlpha = 1;
        }

        ctx.fillStyle = "#FFB500";
        roundRect(
          ctx,
          3 * barsNo * commentStartPos - 1.25,
          55,
          2.5,
          100 + 12.5 * (noCommentRows - 1),
          1.25,
          true,
          false
        );

        ctx.fillStyle = "#FFB500";
        roundRect(
          ctx,
          3 * barsNo * commentEndPos - 1.25,
          55,
          2.5,
          100 + 12.5 * (noCommentRows - 1),
          1.25,
          true,
          false
        );
      }
    } else if (editingComment) {
      const findComment = (selectedComment) => {
        for (let i = 0; i < commentList.length; i++) {
          for (let j = 0; j < commentList[i].length; j++) {
            if (commentList[i][j].CurrentTime === selectedComment.id) {
              return commentList[i][j];
            }
          }
        }
      };

      const comment = findComment(editingComment);
      ctx.globalAlpha = 0.5;
      ctx.fillStyle = comment.Colour.hex;

      roundRect(
        ctx,
        3 * barsNo * (comment.StartTime / duration) - 1.25,
        55,
        2.5,
        100 + 12.5 * (noCommentRows - 1),
        1.25,
        true,
        false
      );

      roundRect(
        ctx,
        3 * barsNo * (comment.EndTime / duration) - 1.25,
        55,
        2.5,
        100 + 12.5 * (noCommentRows - 1),
        1.25,
        true,
        false
      );

      ctx.globalAlpha = 1;
    }
  };

  return <Canvas draw={draw} />;
}

export default CreateCanvas;
