import CommentForm from "./commenting/commentForm";
import CommentList from "./commenting/commentList";
import addCommentSVG from "../images/add-comment.svg";
import formatTime from "./format_time/formatTime";
import { commentColour } from "./commentColour";

export default function CommentSection({
  commentList,
  updateCommentList,
  commentStartTime,
  commentEndTime,
  cancelComment,
  findCommentRow,
  toggleIsCommentPlaying,
}) {
  const addComment = (commentText) => {
    const tempCommentList = [...commentList];
    const commentRow = findCommentRow(
      commentList,
      commentStartTime,
      commentEndTime
    );
    if (commentRow !== false) {
      const newComment = {
        StartTime: commentStartTime,
        EndTime: commentEndTime,
        Comment: commentText,
        Colour: commentColour(),
        CurrentTime: new Date().getTime(),
      };
      tempCommentList[commentRow].push(newComment);
      updateCommentList(tempCommentList);
      cancelComment();
    } else {
      alert("You cannot have more than 10 overlapping comments.");
    }
  };

  return (
    <div>
      <CommentForm
        startTime={commentStartTime}
        addCommentSVG={addCommentSVG}
        addComment={addComment}
      />
      <CommentList
        commentList={commentList}
        formatTime={formatTime}
        updateCommentList={updateCommentList}
        findCommentRow={findCommentRow}
      />
    </div>
  );
}
