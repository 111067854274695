import skipForwardButton from "../images/skip_forward.svg";
import skipBackwardButton from "../images/skip_back.svg";

export default function SkipButtons({
  loaded,
  currentTime,
  startTime,
  setStartTime,
  endTime,
  duration,
  setAudioTime,
}) {
  //handle skip backwards click
  const handleSkipBackwardClick = () => {
    if (loaded) {
      if (currentTime > 0.1 * (endTime - startTime)) {
        if (currentTime > startTime + 0.1 * (endTime - startTime)) {
          setAudioTime(currentTime - 0.1 * (endTime - startTime));
        } else {
          setAudioTime(currentTime - 0.1 * (endTime - startTime));
          const newStartTime = currentTime - 0.1 * (endTime - startTime);
          setStartTime(newStartTime);
        }
      } else {
        setAudioTime(0);
        if (endTime < duration) {
          setStartTime(0);
        }
      }
    }
  };

  //handle skip forwards click
  const handleSkipForwardClick = () => {
    if (loaded) {
      if (currentTime < duration - 0.1 * (endTime - startTime)) {
        if (currentTime < 0.9 * (endTime - startTime) + startTime) {
          setAudioTime(currentTime + 0.1 * (endTime - startTime));
        } else {
          setAudioTime(currentTime + 0.1 * (endTime - startTime));
          const newStartTime = startTime + 0.1 * (endTime - startTime);
          setStartTime(newStartTime);
        }
      } else {
        setAudioTime(duration);
        if (startTime > 0) {
          setStartTime(duration - (endTime - startTime));
        }
      }
    }
  };

  return (
    <>
      <button onClick={handleSkipBackwardClick} className="buttonNoStyle">
        <img src={skipBackwardButton} alt="Skip backwards" id="skipBackward" />
      </button>
      <button onClick={handleSkipForwardClick} className="buttonNoStyle">
        <img src={skipForwardButton} alt="Skip forwards" id="skipForward" />
      </button>
    </>
  );
}
