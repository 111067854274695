import React, { useMemo } from "react";

export default function ZoomFollowerSection({
  startTime,
  endTime,
  duration,
  currentTime,
  setStartTime,
  setAudioTime,
  zoom,
}) {
  const divStyle = {
    "--dummy": 148 / Math.pow(2, { zoom }.zoom - 1) + "px",
  };

  const playheadStyle = {
    left: 146 * (currentTime / duration) + "px",
  };

  // sectionValue, handleMoveSection, divStyle

  const sectionValue = useMemo(() => {
    if (startTime > 0.0001) {
      return startTime / (duration - (endTime - startTime));
    } else {
      return 0;
    }
  }, [startTime, endTime, duration]);

  //   !! Change this functionality
  const handleMoveSection = (e) => {
    const newStartTime = e.target.value * (duration - (endTime - startTime));
    const newEndTime = newStartTime + (endTime - startTime);
    let newCurrentTime = null;
    if (currentTime < newStartTime) {
      // newCurrentTime = newStartTime;
      // console.log("off screen left");
    } else if (currentTime > newEndTime) {
      // newCurrentTime = newEndTime;
      // console.log("off screen right");
    }
    setStartTime(newStartTime);
    if (newCurrentTime !== null) {
      setAudioTime(newCurrentTime);
    }
  };

  return (
    <div id="zoomFollowerSection">
      <input
        type="range"
        min="0"
        max="1"
        step="0.001"
        value={sectionValue}
        id="zoomFollower"
        onChange={(e) => handleMoveSection(e)}
        style={divStyle}
      />
      <div id="zoomFollowerPlayhead" style={playheadStyle}></div>
    </div>
  );
}
