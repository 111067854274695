import userAvatar from "../../images/user.svg";
import colours from "../waveform/colours";

export default function CommentList({
  commentList,
  formatTime,
  updateCommentList,
  findCommentRow,
}) {
  const TimeFormat = ({ startTime, endTime, comment, colour }) => {
    if (endTime === null) {
      return (
        <div id="comment-content">
          <span id="comment-time" style={{ color: colour }}>
            {formatTime(startTime)}
          </span>
          <span id="comment-text"> {comment}</span>
        </div>
      );
    } else {
      return (
        <div id="comment-content">
          <span id="comment-time" style={{ color: colour }}>
            {formatTime(startTime)} - {formatTime(endTime)}
          </span>
          <span id="comment-text"> {comment}</span>
        </div>
      );
    }
  };

  const deleteComment = (currentTime) => {
    const newCommentList = [...sortedCommentList];
    const newSortedList = [[], [], [], [], [], [], [], [], [], []];
    // find row and index (i, j) of comment in commentList where comment.CurrentTime === currentTime
    for (let i = 0; i < sortedCommentList.length; i++) {
      if (sortedCommentList[i].CurrentTime === currentTime) {
        const commentColour = colours.find(
          (colour) => colour === sortedCommentList[i].Colour
        );
        commentColour.used = false;
        newCommentList.splice(i, 1);
      }
    }
    // sort newCommentList into newSortedList
    for (let i = 0; i < newCommentList.length; i++) {
      const commentRow = findCommentRow(
        newSortedList,
        newCommentList[i].StartTime,
        newCommentList[i].EndTime
      );
      console.log(commentList[i], commentRow);
      if (commentRow !== false) {
        newSortedList[commentRow].push(newCommentList[i]);
      }
    }
    updateCommentList(newSortedList);
    console.log(newSortedList);
  };

  const cssBorder = (colour) => {
    return `2px solid ${colour}`;
  };

  // new comment list with all comments sorted by current time, removing the rows
  const sortedCommentList = commentList
    .map((row) => row.map((comment) => comment.CurrentTime))
    .flat()
    .sort((a, b) => a - b)
    .map((currentTime) => {
      const commentRow = commentList.find((row) =>
        row.find((comment) => comment.CurrentTime === currentTime)
      );
      return commentRow.find((comment) => comment.CurrentTime === currentTime);
    });

  return (
    <div id="comment-list">
      {sortedCommentList.map((comment) => (
        <div
          key={comment.CurrentTime}
          id="comment"
          style={{ border: cssBorder(comment.Colour.hex) }}
        >
          <div id="comment-title">
            <img src={userAvatar} alt="avatar" id="avatar" />
            <div id="comment-title-text"> My User Name - Organisation</div>
          </div>
          <TimeFormat
            startTime={comment.StartTime}
            endTime={comment.EndTime}
            comment={comment.Comment}
            colour={comment.Colour.hex}
          />
          <div id="comment-footer">17/11/2019 - 10:36 AM</div>
          <button onClick={() => deleteComment(comment.CurrentTime)}>
            delete
          </button>
        </div>
      ))}
    </div>
  );
}
