import { useState } from "react";

export default function CommentForm({ startTime, addCommentSVG, addComment }) {
  const [commentText, setCommentText] = useState("");

  const handleChange = (event) => {
    setCommentText(event.target.value);
  };

  const handleSubmit = (event) => {
    if (startTime !== null && commentText !== "") {
      event.preventDefault();
      addComment(commentText);
      setCommentText("");
    }
  };

  return (
    <div className="form-container">
      <form>
        <div id="comment-div">
          <input
            id="comment-input"
            type="text"
            name="name"
            placeholder="Type your comment here…"
            value={commentText}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <img
            onClick={handleSubmit}
            src={addCommentSVG}
            alt="Add comment"
            id="add-comment-button"
          />
        </div>
      </form>
    </div>
  );
}
