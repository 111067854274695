//handle load audio
const handleLoadAudio = (song) => {
  const AudioContext = window.AudioContext || window.webkitAudioContext;
  const audioCtx = new AudioContext();
  const audioElement = document.createElement("audio");

  loadSong(song);

  function loadSong(song) {
    audioElement.src = song;
  }
  const track = audioCtx.createMediaElementSource(audioElement);

  const gainNode = audioCtx.createGain();
  track.connect(gainNode).connect(audioCtx.destination);

  // setAudioElement(audioElement);
  return audioElement;
};

export default handleLoadAudio;
