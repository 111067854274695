import ZoomButtons from "./zoomButtons";
import ZoomFollowerSection from "./zoomFollowerSection";
import ZoomSlider from "./zoomSlider";

export default function zoomControls({
  zoom,
  setZoom,
  maxZoom,
  startTime,
  setStartTime,
  endTime,
  duration,
  currentTime,
  currentPercentage,
  loaded,
  setAudioTime,
}) {
  const findZoomData = (zoomSectionSize, newZoom) => {
    if (
      newZoom < zoom &&
      Math.round((1000 * currentTime) / duration) / 1000 !==
        Math.round((1000 * (currentTime - startTime)) / (endTime - startTime)) /
          1000
    ) {
      return (
        ((1 - zoomSectionSize) * startTime * duration) /
        (startTime + duration - endTime)
      );
    } else {
      return currentTime - zoomSectionSize * currentPercentage * duration;
    }
  };

  const setNewZoom = (zoomSectionSize, newZoom) => {
    setStartTime(findZoomData(zoomSectionSize, newZoom));
    setZoom(newZoom);
  };

  return (
    <>
      <ZoomSlider
        maxZoom={maxZoom}
        zoom={zoom}
        setNewZoom={setNewZoom}
        loaded={loaded}
      />
      <ZoomButtons
        loaded={loaded}
        zoom={zoom}
        setNewZoom={setNewZoom}
        maxZoom={maxZoom}
      />
      <ZoomFollowerSection
        startTime={startTime}
        endTime={endTime}
        duration={duration}
        currentTime={currentTime}
        setStartTime={setStartTime}
        setAudioTime={setAudioTime}
        zoom={zoom}
      />
    </>
  );
}
