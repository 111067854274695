import colours from "./waveform/colours";

//add a new comment to the comment list
export function commentColour() {
  //find the first colour in the array colours that is not used
  const commentColour = colours.find((colour) => colour.used === false);
  //update the colour used property to true
  commentColour.used = true;
  return commentColour;
}
