import React from "react";
import Canvas from "./canvas";

const getOpacity = (currentPercentage, mouseEntered, temporaryCursor) => {
  if (mouseEntered) {
    if (
      temporaryCursor > currentPercentage - 0.06 &&
      temporaryCursor < currentPercentage + 0.06
    ) {
      return 0.5;
    } else if (
      temporaryCursor < currentPercentage &&
      temporaryCursor > currentPercentage - 0.1
    ) {
      return 0.5 + (currentPercentage - temporaryCursor - 0.06) * 25 * 0.5;
    } else if (
      temporaryCursor > currentPercentage &&
      temporaryCursor < currentPercentage + 0.1
    ) {
      return 0.5 + (temporaryCursor - currentPercentage - 0.06) * 25 * 0.5;
    } else {
      return 1;
    }
  }
};

function CreateProgressCanvas({
  barsNo,
  currentPercentage,
  currentTime,
  temporaryCursor,
  mouseEntered,
  zoomPercent,
  commentList,
  canvasHeight,
  setPlayheadHoverRegion,
  playheadHoverRegion,
  addingComment,
  editingComment,
}) {
  const draw = (ctx) => {
    ctx.canvas.width = 3 * barsNo;
    ctx.canvas.height = canvasHeight;

    // Get the DPR and size of the canvas
    var dpr = window.devicePixelRatio;
    var rect = ctx.canvas.getBoundingClientRect();

    // Set the "actual" size of the canvas
    ctx.canvas.width = rect.width * dpr;
    ctx.canvas.height = rect.height * dpr;

    // Scale the context to ensure correct drawing operations
    ctx.scale(dpr, dpr);

    // Set the "drawn" size of the canvas
    ctx.canvas.style.width = `${rect.width}px`;
    ctx.canvas.style.height = `${rect.height}px`;

    const minutes = (time) =>
      Math.floor((time / 60) % 60)
        .toFixed(0)
        .padStart(2, "0");
    const seconds = (time) =>
      Math.floor(time % 60)
        .toFixed(0)
        .padStart(2, "0");
    const milliseconds = (time) =>
      Math.round(time * 100)
        .toFixed(0)
        .padStart(2, "0")
        .slice(-2);

    const findRowsNo = (row) => row.length === 0;
    const noCommentRows = commentList.findIndex(findRowsNo);

    //cursor at current time
    ctx.fillStyle = "#D3DEE0";
    // ctx.fillRect(-1.5 + 3 * barsNo * currentPercentage, 97.3, 3, 7.5);
    if (noCommentRows === 0) {
      ctx.fillRect(-1.5 + 3 * barsNo * currentPercentage, 97.3, 3, 15 + 5);
    } else {
      ctx.fillRect(
        -1.5 + 3 * barsNo * currentPercentage,
        97.3,
        3,
        15 + 12.5 * (noCommentRows - 1) + 5
      );
    }
    const newPlayheadHoverRegion = {
      x: -4.5 + 3 * barsNo * currentPercentage,
      y: 97.3,
      width: 9,
      height: 15 + 12.5 * (noCommentRows - 1) + 5,
    };
    if (
      JSON.stringify(newPlayheadHoverRegion) !==
      JSON.stringify(playheadHoverRegion)
    ) {
      setPlayheadHoverRegion(newPlayheadHoverRegion);
    }

    //timestamp of the current time of the audio file
    if (!addingComment && !editingComment) {
      ctx.globalAlpha = getOpacity(
        currentPercentage,
        mouseEntered,
        temporaryCursor
      );
      ctx.textAlign = "center";
      ctx.font = " 8px AeonikBold";
      if (currentPercentage < 0.03) {
        ctx.fillStyle = "#0A0A0A";
        ctx.fillRect(0, 40 - zoomPercent * 6, 42, 15);
        ctx.fillStyle = "#D3DEE0";
        ctx.fillText(minutes(currentTime) + ":", 7, 50 - zoomPercent * 6);
        ctx.fillText(seconds(currentTime) + ".", 19, 50 - zoomPercent * 6);
        ctx.fillText(milliseconds(currentTime), 19 + 11, 50 - zoomPercent * 6);
        ctx.fillText("| ", 39, 50 - zoomPercent * 6);
      } else if (currentPercentage > 0.97) {
        ctx.fillStyle = "#0A0A0A";
        ctx.fillRect(3 * barsNo - 44, 40 - zoomPercent * 6, 44, 15);
        ctx.fillStyle = "#D3DEE0";
        ctx.fillText("| ", -20 - 20 + 3 * barsNo, 50 - zoomPercent * 6);
        ctx.fillText(
          minutes(currentTime) + ":",
          -20 - 12 + 3 * barsNo,
          50 - zoomPercent * 6
        );
        ctx.fillText(
          seconds(currentTime) + ".",
          -20 + 3 * barsNo,
          50 - zoomPercent * 6
        );
        ctx.fillText(
          milliseconds(currentTime),
          -20 + 11 + 3 * barsNo,
          50 - zoomPercent * 6
        );
      } else {
        ctx.fillStyle = "#0A0A0A";
        ctx.fillRect(
          3 * barsNo * currentPercentage - 22.5,
          40 - zoomPercent * 6,
          44,
          15
        );
        ctx.fillStyle = "#D3DEE0";
        ctx.fillText(
          "| ",
          -20 + 3 * barsNo * currentPercentage,
          50 - zoomPercent * 6
        );
        ctx.fillText(
          minutes(currentTime) + ":",
          -12 + 3 * barsNo * currentPercentage,
          50 - zoomPercent * 6
        );
        ctx.fillText(
          seconds(currentTime) + ".",
          0 + 3 * barsNo * currentPercentage,
          50 - zoomPercent * 6
        );
        ctx.fillText(
          milliseconds(currentTime),
          11 + 3 * barsNo * currentPercentage,
          50 - zoomPercent * 6
        );
        ctx.fillText(
          "| ",
          20 + 3 * barsNo * currentPercentage,
          50 - zoomPercent * 6
        );
      }
    }
  };

  return <Canvas draw={draw} />;
}

export default CreateProgressCanvas;
